<template>
  <div class="inner-layout">

    <div class="detailP-subject-box">
      <div class="heading-1">{{ item.title }}</div>
    </div>

    <div class="detailP-profile-lable-box">
      <div class="profile-set">
        <div class="profile-info">
          <div><span class="nickname">{{ item.chanName }}</span></div>
          <div class="hit-date">
            <span class="profile-dot">조회수 {{ item.view ? Number(item.view).numberFormat() : 0 }}</span>
            <span class="profile-dot">{{ $getDateFormat(item.uploadDate, 'Y.m.d') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="detailP-video">
      <div class="video-box">
        <div><iframe width="560" height="315" :src="item.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      </div>
      <div class="md-container">
        <div class="md-theName">{{list.theName}}</div>
        <div class="md-item-list">
          <div class="md-item" v-for="(item, index) in list.list" :key="index" :class="{'on' : item.boIdx == boIdx}" @click="change(item.boIdx)">
            <div class="item-img">
              <div class="thumb-img"><image-alt :src="item.thumbnail" altType="list"></image-alt></div>
            </div>
            <div class="item-con">
              <div class="item-title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "md",
  data: function () {
    return {
      idx: this.$route.params.id,
      boIdx: this.$route.params.idx,
      item: {
        boIdx: 0,
        chanName: null,
        title: null,
        uploadDate: null,
        url: null,
        view: 0,
      },
      list: {
        idx: null,
        theName: null,
        theSubName: null,
        list: [],
      },
    }
  },
  created() {
    if (!(this.idx && this.boIdx)) this.$router.push('/md')
    this.getList()
    this.setItem()
  },
  methods: {
    async getList() {
      const { result, data } = await this.$api.getMdListByIdx({ idx: this.idx })
      if (result === 'success') {
        this.list = {
          idx: data.idx,
          theName: data.theName,
          thisSubName: data.theSubName,
          list: data.list,
        }
      }
    },
    async setItem() {
      const { result, data } = await this.$api.getMdDetail({ boIdx: this.boIdx })
      if (result === 'success') this.item = data
    },
    change(boIdx) {
      this.$router.push(`/md/${this.idx}/${boIdx}`)
    },
  }
}
</script>
